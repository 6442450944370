/* Module: S */
.quicksections {
    background-color: rgba(85, 87, 89, 0.12);
    padding: 0 2rem;

	&__container {
	    margin: pxToRem(-60) auto 0 auto;
		max-width: 80rem;
		background-color: #fff;
		position: relative;
		z-index: 9;
	}

    ul {
        margin: 0 -1px;
	    @include sq-flex-box;
	    @include sq-justify-content(center);

        li {
            width: 16.667%;
            border-top: 0;
            border-bottom: 0;
        }
    }
}