/* Module: S */
//Icons
.close-icon {
    display: none;
    .is-active & {
        display: block;
    }
}

.search-icon,
.menu-icon {
    display: block;
    .is-active & {
        display: none;
    }
}

.search-icon,
.menu-icon,
.close-icon {
    fill: #00447c;
}

//Header
.main-header {
    background: #fff;
    position: relative;

    .is-mobile & {
        z-index: 4;
    }
}

.top-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: $page__max-width;
    padding-left: 1rem;
    padding-right: 1rem;
    @extend %clearfix;
}

.top-nav__wrapper {
    background: $top-nav__bg;
    padding: .75rem;
}

.top-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
}

.top-nav__item {
    color: $top-nav__color;
    float: left;
    line-height: 1rem;
    padding: 0 1.25rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $top-nav__color;
    }

    &:last-child {
        padding: 0 0 0 1rem;
        &::after {
            display: none;
        }
    }
}

.top-nav__link {
    font-size: pxToRem(14);
    color: $top-nav__color;
    text-decoration: none;
    &:hover {
        color: $top-nav__color;
        text-decoration: underline;
    }
    &:focus {
        color: $top-nav__color;
    }
}

.main-header__wrapper {
    @include sq-flex-box;
    @include sq-align-items(center);
    @include sq-justify-content(flex-end);
    @include placeholder-color($header-search-input__color);
}

.logo__link {
    display: inline-block;
    margin-right: auto;
    padding: pxToRem(10) pxToRem(30);
}

.logo__image {
    display: block;
    height: pxToRem(64);
}

.main-nav {
    display: none;
}

.main-nav__list {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-nav__item {
    float: left;
    position: relative;
    &::before {
        background: #f99d1c;
        content: '';
        display: block;
        height: 3px;
        left: 1.25rem;
        right: 1.25rem;
        bottom: 0.5rem;
        margin: 0 auto;
        position: absolute;
        transform: scaleX(0);
        will-change: transform;
        transition: transform .3s ease;
    }
    &:hover {
        &::before {
            transform: scaleX(1);
        }
    }
}

.main-nav__link {
    .active & {
        font-weight: 600;
    }
}

.header-search__form {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    .sr-only {
        color: #3e7e87;
    }
}

.header-search__input {
    border: none;
    padding: 2rem pxToRem(60) 2rem pxToRem(30);
    vertical-align: top;
    width: 100%;
    background: $header-search-mobile__bg;
    font-weight: 600;
    @include sq-transition(.15s background-color linear);
    color: $header-search-input__color;

    &:focus,
    &:active {
        outline: 0;
        background-color: darken($main-menu__bg, 5%);
    }
}

.header-search__submit-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    background: none;
    border: none;
    padding: .5rem;
    float: right;
}


//mobile buttons
.main-menu__btn,
.header-search__mobile-btn,
.header-search__desktop-btn {
    border: none;
    width: 50px;
    height: 50px;
    background: none;
    padding: 0;
    margin-right: 1rem;
    display: inline-block;
    border-radius: 0;
}

.header-search__mobile-btn {
    background-color: $azure;
    padding: 14px;

    &:hover {
        background-color: rgba($azure, 0.7);
    }
}

.header-search__desktop-btn {
    background-color: $azure;
    padding: 14px;
    display: none;

    &:hover {
        background-color: rgba($azure, 0.7);
    }
}

//Mega menu
.mega-menu__wrapper {
    padding: 0;
    position: absolute;
    width: 100%;
    display: none;
    height: auto;
    background: $mobile-menu-lvl1__bg;

    .mega-menu__item:last-child {
        border-bottom: 0;
    }
}

.menu-arrow {
    display: block;
    top: 0;
    height: 100%;
    position: absolute;
    right: 0;
    width: pxToRem(60);
    @include sq-transition(.3s all ease-in-out);

    &:hover,
    &:focus {
        outline: 0;
    }

    &::after {
        border-left: 2px solid $menu-arrow__color;
        border-top: 2px solid $menu-arrow__color;
        content: '';
        position: absolute;
        display: block;
        height: 8px;
        left: 50%;
        top: 50%;
        width: 8px;
        margin-top: -4px;
        margin-left: -4px;
        @include sq-transform(rotate(-135deg));
        @include sq-transition(.15s transform linear);
        transform-origin: center center;

        .lvl-2 & {
            border-left: 2px solid #fff;
            border-top: 2px solid #fff;
        }
    }

    .is-expanded > .mega-menu__feature &,
    .is-expanded > .mega-menu__sub-nav-title & {
        &::after {
            margin-top: -2px;
            @include sq-transform(rotate(45deg));
        }
    }
}

.mega-menu__close-button {
    display: none;
}

.mega-menu__item {
    padding: 0;
    opacity: 1;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mega-menu__feature {
    width: 100%;
    padding: 0;
    float: none;
}

.mega-menu__feature-description {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
}

.mega-menu__feature-image {
    display: none;
}

.mega-menu__feature-title,
.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: 0;
}

.mega-menu__feature-link {
    position: relative;
    display: block;
    font-size: 1.125rem;
    font-weight: bold;
    padding: pxToRem(20) pxToRem(60) pxToRem(20) pxToRem(20);
    text-decoration: none;
    color: $color__tertiary;

    &:hover,
    &:focus {
        color: $color__tertiary;
        outline: 0;
    }
}

.mega-menu__navs-wrapper {
    width: 100%;
    display: none;
    overflow: hidden;
    background: $mobile-menu-lvl2__bg;

    .mega-menu__list {
        width: 100%;
        padding: 0;
    }
}

.mega-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;

    .lvl-2 & {
        display: none;
        background: $mobile-menu-lvl3__bg;
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    position: relative;
    display: block;
    font-size: pxToRem(14);
    font-weight: 400;
    padding: pxToRem(14) pxToRem(62) pxToRem(14) pxToRem(40);
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
    }
}

.mega-menu__sub-nav-text {
    &:hover {
        text-decoration: none;
    }
}

.menu-overlay {
    background: rgba(0, 0, 0, .6);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.3s opacity ease-in-out, .3s visibility ease-in-out);

    &.show {
        opacity: 1;
        visibility: visible;
    }
}
