/* Module: S */
.landscape-carousel__item {
    padding: 4rem 2.5rem 15rem 2.5rem;
}

.landscape-carousel__caption-cta {
	a {
		display: inline-block;
		width: auto;
	}
} 