/* Module: S */
.quicklinks {
    margin: 0 auto;
    max-width: 80rem;
    padding: 0 1.5rem;
}

.quicklinks__heading {
    font-size: 1.125rem;
    font-style: italic;
    line-height: 1.33;
    font-weight: normal;
    color: #f99d1c;
    padding-left: 1rem;
}

.tab__button.quicklinks__link {
    white-space: nowrap;
    color: #fff;
    background: none;
    font-size: 0.938em;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 2;
    display: inline-block;
    text-decoration: none;
    border: 2px solid #fff;
    text-transform: uppercase;
    padding: 0.3rem 1rem;
    margin: 0.3rem 0.25rem;
    @include sq-transition(background-color 0.3s, border-color 0.3s);
    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        background-color: #f99d1c;
        border-color: #f99d1c;
    }
}

button.accordion__heading {
    color: #00447c;
}

.home .active>button.accordion__heading::after {
    background-color: #fff;
}

.home {
    button.accordion__heading::before, button.accordion__heading::after {
        background-color: #f99d1c;
    }
}

button.accordion__heading:hover, button.accordion__heading:focus {
    color: #fff;
    background-color: #00447c;
}

.tabs__contents.quicklinks__tabs {
    border: 0px;
}

.tab__content.quicklinks__tab-content {
    opacity: 0.7;
    font-size: 0.938em;
    font-weight: bold;
    letter-spacing: 0.8px;
    background-color: #000;
    padding: 1rem;
}

.quicklinks__tab-link {
    color: #00447c;
    text-decoration: none;
    border-bottom: 1px solid #f99d1c;
    margin-right: 15px;
    &:hover, &:focus {
        color: #f99d1c;
        text-decoration: none;
    }
}