/* Module: S */
.homepage-news {
    background-color: #fff;
    padding: 0 1.375rem;

    &__container {
        max-width: 84rem;
        margin: 0 auto;
    }

    &__info {
        float: left;
        width: 33.333%;
        text-align: left;
        padding: 4rem pxToRem(20) 3.5rem pxToRem(20);

        p {
            display: block;
        }
    }

    &__main {
        float: left;
        width: 66.667%;
        margin-top: -5rem;
        padding: 0 0 3.5rem 0;
    }

    &__wrapper {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
    }

    &__image {
        display: block;
    }

    &__tag {
        display: block;
    }
}