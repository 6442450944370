/* Module: S */
.main-header__wrapper {
    margin: 0 auto;
    max-width: $page__max-width;
    padding-top: 0;
    padding-bottom: 0;
}

.logo__link {
    padding: pxToRem(18) 0;
}

.logo__image {
    height: pxToRem(94);
}

.header-search__wrapper {
    position: relative;
}

.header-search__form {
    top: -7px;
    right: pxToRem(60);
    left: auto;
    background-color: #fff;
    width: 0;
    z-index: 10;
    display: block;
    @include sq-transition(width 0.3s);
    overflow: hidden;

    &.active {
        width: 26rem;
    }
}

.header-search__input {
    position: relative;
    border: none;
    padding: pxToRem(14) pxToRem(35) pxToRem(14) 1rem;
    vertical-align: top;
    background-color: $header-search-focus__bg!important;
}

.header-search__submit-button {
    float: none;
    position: absolute;
    top: 0;
    right: 3px;
    width: 50px;
    padding: 14px;
    @include ie-transparent();
    @include sq-transform(none);
}

.main-nav {
    display: block;
    @include sq-flex(1 0 auto);
}

.main-nav__list {
    padding-top: 35px;
    opacity: 1;
    @include sq-transition(opacity 0.3s);

    &.hidden {
        opacity: 0;
    }
}

.main-nav__link {
    color: #4d4d4d;
    text-decoration: none;
    font-size: pxToRem(18);
    font-weight: bold;
    padding: 1rem 1.25rem;
    display: block;
    @include sq-transition(.2s color linear);

    &:hover,
    &:focus {
        color: #4d4d4d;
    }

    &::before {
        @include sq-transition(.3s ease opacity);
        bottom: -19px;
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 6px 10px;
        border-color: transparent transparent #00447c transparent;
        opacity: 0;
        z-index: 2;
        margin-left: -10px;
    }

    &.main-nav__link--active {
        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 1;
        }
    }
}

.menu-arrow {
    display: none;
}

.mega-menu__wrapper {
    padding: pxToRem(36) 0;
    display: block;
    width: 100%;
    height: 400px;
    background: $mega-menu__bg;
    overflow: hidden;
    border-top: 3px solid #00447c; 
}

.mega-menu__items {
    max-width: $page__max-width;
    margin: 0 auto;
    position: relative;
}
.mega-menu__close-button {
    background: none;
    border: none;
    padding: 0;
    display: block;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 2rem;
    top: 0;
    .close-icon {
        display: block;
    }
}

.mega-menu__item {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: $page__max-width;
    padding-left: 2rem;
    padding-right: 3.5rem;
    opacity: 0;
    visibility: hidden;
    border-bottom: 0;
    @include sq-transform(translateX(-50%));
    @include sq-transition(.3s ease-in-out opacity, .3s ease-in-out visibility);

    &.mega-menu__item--visible {
        opacity: 1;
        visibility: visible;
    }
}

.mega-menu__feature {
    width: 30%;
    padding-right: 4rem;
    float: left;
}

.mega-menu__feature-title {
    margin-bottom: .75rem;
}

.mega-menu__feature-description {
    display: block;
    line-height: 1.56;
    color: $mega-menu-link__color;
}

.mega-menu__feature-image {
    display: block;
    padding-bottom: 0.5rem;
}

.mega-menu__feature-link {
    color: $mega-menu-link__color;
    border: none;
    text-decoration: none;
    font-weight: bold;
    font-size: pxToRem(24);
    padding: 0;
    &:hover {
        text-decoration: underline;
    }
}

.mega-menu__navs-wrapper {
    background: none;
    display: block;
    float: left;
    width: 70%;
    padding-top: 0;
    padding-bottom: 0;

    > .mega-menu__list {
        width: 33%;
        padding-right: 3rem;

        &:nth-child(3n) {
            padding-right: 0;
        }

        &:nth-child(n+4) {
            margin-top: 2rem;
        }
    }
}

.mega-menu__list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;

    .lvl-2 & {
        display: block;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: 0.75rem;
    padding-bottom: .4rem;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 3px;
        background-color: #f99d1c;
    }

    .mega-menu__sub-nav-link,
    .mega-menu__sub-nav-text {
        font-size: 1.125rem;
        color: #00aeef;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        &:hover {
            color: #00aeef;
        }
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    color: $mega-menu-link__color;
    display: inline-block;
    font-size: 1rem;
    line-height: 2.69;
    font-weight: bold;
    padding: 0;
    text-decoration: none;
    @include sq-transition(.1s color ease-in-out);

    &:hover,
    &:focus {
        color: $color__primary;
        text-decoration: underline;
    }
}

.mega-menu__sub-nav-text {
    font-weight: 600;
    &:hover {
        text-decoration: none;
    }
}

.main-menu__btn{
    display: none;
}

.header-search__mobile-btn {
    margin: 35px 0 0 2rem;
    display: none;
}

.header-search__desktop-btn {
    margin: 35px 0 0 2rem;
    display: block;
}

.menu-overlay {
    background-color: transparent;
}
