/* Module: Pagination */
.pagination {
    list-style: none;
    margin: 2.5rem 0 1.5rem 0;
    padding: 0;
    @include sq-flex-box;
    @include sq-justify-content(center);
    @include sq-flex-wrap(wrap);
}

.pagination__item {
    margin: 0 .5rem;
    text-align: center;
}

.pagination__link,
.pagination__item--current > span {
    color: $color__secondary;
    display: block;
    position: relative;
    line-height: 1.25;
    text-decoration: none;
    padding: .5rem;
    &::after {
        -moz-transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
        -webkit-transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
        transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
        background-color: $color__primary;
        top: 100%;
        left: 0;
        content: '';
        height: pxToRem(3);
        position: absolute;
        width: .0001%;
    }
}

.pagination__link {
    @include sq-transition(.15s background-color ease-in-out);
    &:hover,
    &:focus {
        color: $color__secondary;
        &::after {
            width: 100%;
        }
    }
}

.pagination__item--current {
    font-weight: 600;

     > span {
         border-bottom: 3px solid $color__primary;
     }
}

.pagination__previous {
    .pagination__link::before {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        border-left: 4px solid $color__secondary;
        border-bottom: 4px solid $color__secondary;
        margin-right: .4rem;
        @include sq-transform(rotate(45deg));
    }
}

.pagination__next {
    .pagination__link::before {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        border-right: 4px solid $color__secondary;
        border-bottom: 4px solid $color__secondary;
        margin-left: .4rem;
        @include sq-transform(rotate(-45deg));
    }
}
