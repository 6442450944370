/* Module: S */
.homepage-news {
    background-color: #f1f1f1;
    @extend %clearfix;

    &__info {
        padding: pxToRem(30) pxToRem(20) pxToRem(20) pxToRem(20);
        text-align: center;

        p {
            display: none;
            font-size: 1.125em;
            font-style: italic;
            line-height: 1.61;
            color: $color__primary;
            max-width: pxToRem(260);
        }

        a {
            display: inline-block;
            background-color: #f99d1c;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.938rem;
            font-weight: bold;
            padding: pxToRem(10) 3.5rem pxToRem(10) 1.25rem;
            color: #fff;
            position: relative;
            margin-top: 1rem;
            min-width: pxToRem(220);
            text-align: left;
            border: 2px solid #f99d1c;
            @include font-smoothing;
            @include sq-transition(background-color 0.3s, color 0.3s);

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                right: 1.25rem;
                width: 17px;
                background: url(mysource_files/arrow-right.svg) right center no-repeat;
            }

            &:hover {
                background-color: transparent;
                color: $color__primary;
            }
        }
    }

    &__main {
        padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
        @extend %clearfix;
    }

    &__item {
        padding-top: pxToRem(20);
    }

    &__wrapper {
        background-color: #fff;
        position: relative;
    }

    &__content {
        padding: 1.375rem;

        p {
            font-size: 0.938em;
            line-height: 1.6;
            color: #4d4d4d;
            margin: 0;
        }

    }

    &__date {
        font-size: 0.938em;
        font-style: italic;
        line-height: 1.6;
        color: #00aeef;
    }

    &__link {        
        a {
            display: inline-block;
            text-decoration: none;
            font-size: 0.938rem;
            font-weight: bold;
            padding: 0 3.5rem 0 0;
            color: #00aeef;
            position: relative;
            margin-top: 1rem;
            min-width: pxToRem(155);
            @include font-smoothing;
            @include sq-transition(color 0.3s);

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                right: 1.25rem;
                width: 17px;
                background: url(mysource_files/arrow-right.svg) right center no-repeat;
            }

            &:hover {
                color: $color__primary;
            }
        }
    }

    &__image {
        display: none;
        width: 100%;

        img {
            display: block;
            width: 100%;
        }
    }

    &__tag {
        display: none;
        border: 2px solid #fff;
        color: #fff;
        position: absolute;
        top: pxToRem(20);
        right: pxToRem(20);
        font-size: 0.875em;
        font-weight: bold;
        padding: pxToRem(6) pxToRem(9);
        text-transform: uppercase;        
        @include font-smoothing;
    }

    h2 {
        color: $color__primary;
        font-size: 2.25em;
        line-height: 1.25;
        margin: 0;
        padding: 0.5rem 0;
    }

    h3 {
        color: $color__primary;
        font-size: 1.5em;
        margin: 0;
        padding: 0.75rem 0 0.5rem 0;
    }

}