/* Module: S */
.homepage-whatson {
    margin-bottom: -4rem;
    padding: pxToRem(20) 1.375rem 4rem 1.375rem;

    &__container {
        max-width: 84rem;
        margin: 0 auto;
    }

    &__main {
        padding: pxToRem(10) pxToRem(10) 2rem pxToRem(10);
    }

    &__item {
        width: 25%; 
        
        &-large {
            width: 50%;
        }      
    }
}