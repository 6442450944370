/* Module: S */
$white: #ffffff;
$prussian-blue: #00447c;
$gunmetal: rgba(#555759, 0.12);

.tiles {
    background-color: $gunmetal;
    padding: pxToRem(16) pxToRem(12) pxToRem(20) pxToRem(12);
    @include font-smoothing;

    &__more {
        text-align: center;
        padding: 1rem 0;

        button {
            border: none;
            background-color: transparent;
            font-size: 0.938em;
            font-weight: bold;
            line-height: 1.6;
            text-align: center;
            color: #00447c;
            text-transform: uppercase;

            &:after {
                content: url(mysource_files/arrow-down-orange.svg);
                padding-left: 0.5rem;
            }

        }
    }

    &__inner {
        @extend %clearfix;
        position: relative;
    }

    &__tile {
        padding: pxToRem(8);
        width: 100%;
        float: left;
        display: none;

        &:nth-child(-n+4) {
            display: block;
        }

        a {
            background-color: $white;
            color: $prussian-blue;
            min-height: pxToRem(153);
            padding: pxToRem(20);
            position: relative;
            display: block;
            overflow: hidden;

            &:hover {
                color: $prussian-blue;

                .tiles {
                    &__bg {
                        @include sq-transform(scale(1.2));
                    }
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: pxToRem(28);
                right: pxToRem(20);
                width: 17px;
                height: 13px;
                background: url(mysource_files/arrow-right.svg) 0 0 no-repeat;
            }
        }

        &-inverse {
            a {
                background-color: $prussian-blue;
                color: $white;

                &:hover {
                    color: $white;
                }

                &:after {
                    background: url(mysource_files/arrow-right-inverse.svg) 0 0 no-repeat;
                }

            }

            .tiles {
                &__tag {
                    border-color: $white;
                }

                &__hover {
                    background-color: $white;

                    p {
                        color: $prussian-blue;
                    }
                }
            }
        }

        &-tall {
            a {
                min-height: pxToRem(306);

            }
        }
    }

    &__wrapper {
        position: absolute;
        top: pxToRem(20);
        bottom: pxToRem(20);
        left: pxToRem(20);
        right: pxToRem(20);

    }

    &__hover {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $prussian-blue;
        @include sq-flex-box;
        @include sq-justify-content(center);
        @include sq-align-items(center);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        @include sq-transition(opacity 0.3s, visibility 0.3s);

        p {
            max-width: pxToRem(180);
            padding: 0 pxToRem(30) 0 0;
            margin: 0;
            color: $white;
            text-transform: uppercase;
            font-size: pxToRem(14);
            font-weight: bold;
            line-height: 1.43;
            text-align: center;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: pxToRem(3);
                right: 0;
                width: 17px;
                height: 13px;
                background: url(mysource_files/arrow-right-inverse.svg) 0 0 no-repeat;
            }
        }
    }

    &__tag {
        border: 2px solid $prussian-blue;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.875em;
        font-weight: bold;
        padding: pxToRem(6) pxToRem(9);
        text-transform: uppercase;
    }

    &__content {
        position: absolute;
        bottom: pxToRem(-5);
        left: 0;
        right: 0;
    }

    &__title {
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1.25;
        width: 100%;
        padding-right: 2rem;
    }

    &__sub-title {
        font-size: 1.5em;
        font-style: italic;
        line-height: 1.25;
        width: 100%;
        padding-right: 2rem;
    }

    &__text {
        display: none;
    }

    &__bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
        @include sq-transition(all 0.3s);

        &:before {
            content: '';
            position: absolute;
            background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
            top: 0;
            left: 0;
            right: 0;
            height: 25%;
            min-height: pxToRem(110);
        }

        &:after {
            content: '';
            position: absolute;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
            bottom: 0;
            left: 0;
            right: 0;
            height: 50%;
        }
    }
}