/* Module: S */
.search-result__content-inner {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-align-items(flex-start);
    margin-bottom: .5rem;
}

.search-result__type {
    font-weight: 400;
    font-size: pxToRem(14);
    line-height: 1rem;
    margin-bottom: 0;
}
