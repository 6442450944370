/* Module: S */

.tiles {
    padding: 0.5rem 0 8rem 0;

    a {
        padding: 1.75rem;
    }

	&__container {
	    margin: 0 auto;
    	max-width: 84rem;
        padding: 0 1.5rem;
	}

    &__inner {
        position: relative;
    }

    &__wrapper {
        top: 1.75rem;
        bottom: 1.75rem;
        right: 1.75rem;
        left: 1.75rem;
    }

    &__content {
        bottom: pxToRem(-8);
    }

    &__tile {
    	width: 25%;

        a {
            min-height: pxToRem(180);

            &:after {
                bottom: 2.25rem;
                right: 1.75rem;                
            }
        }

        &-tall {
        	a {
        		min-height: pxToRem(580);
        	}
        }

        &-wide {
        	width: 50%;
        }

        &-right {
        	float: right;
        }

        &-negative {
        	position: absolute;
        	left: 0;
        	bottom: 0;
        }
    }

    &__title {
        font-size: 1.250em;
        line-height: 1.5;
    }

    &__sub-title {
        font-size: 1.250em;
        line-height: 1.5;
    }

    &__text {
        display: block;
        font-size: 1.125em;
        padding-top: 0.5rem;
        padding-right: 2rem;
    }
}