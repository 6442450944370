/* Module: S */
/* stylelint-disable */
.main__footer {
    font-size: 90%;
    color: $footer-link__color;
    background-color: $footer__bg;
    padding: 1.5rem 2rem 0 2rem;
    margin-top: 4rem;
    @include font-smoothing;
}
/* stylelint-enable */

.footer-links__list,
.footer-social__list,
.footer-contact__list {
    @extend %list-reset;
}

.footer-links__item,
.footer-contact__item {
    font-size: 1rem;
    margin-bottom: .75rem;
    font-weight: 300;

    &:last-child {
        margin-bottom: 0;
    }
}

/* stylelint-disable */
.footer-links__wrapper {
    width: 100%;
    max-width: 80rem;
}
/* stylelint-enable */

.footer-links__column {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

}

.footer-link,
.footer-phone__link,
.footer-contact__heading {
    font-size: pxToRem(18);
    color: $footer-link__color;
    text-decoration: none;
    line-height: 1.5;
    display: inline-block;

    &:not(.footer-contact__heading):hover,
    &:not(.footer-contact__heading):focus {
        color: $footer-link__color--hover;
        text-decoration: underline;
    }
}

.footer-contact__language {
    padding: 0.5rem 0;    
}

.footer-contact__heading {
    color: $footer-link__color;
    display: block;
    line-height: 2.78;
    font-weight: bold;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: inline-block;
        width: 40px;
        height: 2px;
        background-color: $footer-column-border__color;
    }
}

.footer-contact__text {
    p {
        font-size: 1rem;
        line-height: 1.56;
        margin: 0;
        padding: 0.75rem 0;

        span {
            font-weight: bold;
            color: #00aeef;
            padding-right: 0.5rem;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}

/* stylelint-disable */
.footer-social__wrapper {
    @extend %clearfix;
    padding: 1rem 0;
}
/* stylelint-enable */

.footer-icon {
    fill: #fff;
    width: 1.375rem;
    height: 1.375rem;
    margin: 0.75rem auto;
    display: block;
}

.footer-social__link {
    display: block;
    width: 2.875rem;
    height: 2.875rem;
    margin-right: 0.625rem;
    background-color: $footer-social-icons__bg;
    border-radius: 100%;
    @include sq-transition(.1s background-color ease-in-out);
    float: left;

    &.youtube {
        .footer-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.688rem auto;
        }
    }

    .sr-only {
        color: #fcffff;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background-color: #fff;
        outline: none;

        &.facebook {
            .footer-icon {
                fill: #3b5998;
            }
        }

        &.youtube {
            .footer-icon {
                fill: #bb0000;
            }
        }

        &.linkedin {
            .footer-icon {
                fill: #0077b5;
            }
        }

        &.instagram {
            .footer-icon {
                fill: #bc2a8d;
            }
        }

        &.twitter {
            .footer-icon {
                fill: #0084b4;
            }
        }
    }
}

.footer-links__row {
    border-top: 1px solid rgba(#d2d2d2, 0.33);
    padding: 2.25rem 0;
    margin-top: 2.25rem;
    font-size: 0.875rem;
    line-height: 1.79;
    color: #d2d2d2;
    width: 100%;

    a {
        font-size: 0.875rem;
        line-height: 1.79;
        color: #d2d2d2;

    }

    ul {
        @extend %clearfix;

        li {
            float: left;

            &:after {
                content: '|';
                padding: 0 0.5rem;
            }

            &:first-child {
                width: 100%;

                &:after {
                    display: none;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}
