/* Module: S */
.quicklinks {
    height: 0;
    position: relative;
}

.quicklinks__wrapper {
    position: absolute;
    top: -16rem;
    z-index: 3;
    margin-left: auto;
    right: 0;
    padding: 0 1.9rem;
}

.quicklinks__tab-link {
    color: #fff;
}

.tab__buttons.quicklinks__container{
    display: block;
    float: left;
}

.tab__content.active{
    clear: both;
}

.home .accordion__heading, .tab__button {
    width: auto;
}

.tabs__contents {
    position: static;
}