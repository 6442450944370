/* Module: S */

.tiles {
    &__tile {
    	width: 50%;
    	display: block;

        &-wide {
        	width: 100%;
        }

        &-negative {
        	margin-top: pxToRem(-169);
        }

        a {
            &:hover {
                .tiles {
                    &__hover {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    &__more {
    	display: none;
    }
}