/* Module: Breadcrumbs */
.breadcrumbs__item:not(.breadcrumbs__current) {
    &::after {
        .landing-image__container + .wrapper & {
            border-right-color: #fff;
            border-bottom-color: #fff;
        }
    }
}

.breadcrumbs__link,
.breadcrumbs__current {
    .landing-image__container + .wrapper & {
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, .45);
        background-color: rgba(0,0,0,0.01);
    }
}
