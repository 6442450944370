/* Module: S */
/* Typeahead */

$borders: $color__primary;
$font: $color__tertiary;
$background: #f4f4f4;
$background_active: #eaeaea;
$default-transition: .4s cubic-bezier(.77, 0, .175, 1);
.twitter-typeahead {
    width: 100%;
    .search-page & {
        margin-right: .5rem;
        -ms-flex-negative: 1;
    }
}

.search-page .form__group-inline {
    height: pxToRem(40);
}

.tt-menu {
    background-color: $background;
    color: $font;
    margin-top: pxToRem(1);
    text-align: left;
    width: 100%;
    .rgba & {
        background-color: $background;
    }
    .is-opened & {
        margin-top: 0;
    }
    @media screen and (min-width: 1024px) {
        position: inherit !important;
    }
}

.form__control.typeahead {
    border-width: 1px;
    height: 100%;
    &:focus {
        border-width: 1px;
    }
}

.tt-selectable {
    @include sq-transition(border-color $default-transition, background-color $default-transition);
    cursor: pointer;
    font-size: pxToRem(16);
    padding: pxToRem(10) pxToRem(16);
    font-weight: 600;
    &:not(:last-child) {
        border-color: rgba(0, 0, 0, 0.12);
        border-style: solid;
        border-width: 0 0 pxToRem(1);
        .rgba & {
            border-color: $borders;
        }
    }
    &:focus, &:hover, &.tt-cursor {
        color: $font;
        background-color: $background_active;
    }
}