/* Module: S */
.homepage-whatson {
	&__main {
        padding: pxToRem(10) pxToRem(10) 4rem pxToRem(10);
	}

	&__item {
		float: left;
		width: 50%;
		padding: pxToRem(10);

        a {
            min-height: pxToRem(186);
        } 

		&-large {
			a {
            	min-height: pxToRem(392);
			}
		}
	}
}