/* Module: S */
.landscape-carousel {
    display: none;

    &.slick-initialized {
        display: inherit;
    }
}

.landscape-carousel,
.landscape-carousel__caption-wrapper {
    position: relative;
    overflow: hidden;
}

.landscape-carousel__wrapper {
    position: relative;
}

.landscape-carousel__item {
    padding: 4rem 2.5rem 19rem 2.5rem;
    background-size: cover;
    background-position: center center;

    &:after {
        content: '';
        position: absolute;
        background: -moz-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@media screen and (max-width: 374px) {
    .landscape-carousel__item {
        padding: 4rem 2.5rem 21rem 2.5rem;
    }
}

.landscape-carousel__caption-wrapper {
    z-index: 2;
    @include font-smoothing;
    @include sq-flex-box;
    @include sq-justify-content(center);
    @include sq-flex-direction(column);
}

.landscape-carousel__caption-cta{
    a {
        display: block;
        background-color: #f99d1c;
        width: 100%;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.938rem;
        font-weight: bold;
        padding: pxToRem(10) 3.5rem pxToRem(10) 1.25rem;
        color: #fff;
        position: relative;
        margin-top: 1rem;
        border: 2px solid #f99d1c;
        @include sq-transition(background-color 0.3s);

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            right: 1.25rem;
            width: 17px;
            background: url(mysource_files/arrow-right.svg) right center no-repeat;
            @include sq-transition(all 0.3s);
        }

        &:hover {
            background-color: transparent;

            &:after {
                background: url(mysource_files/arrow-right-inverse.svg) right center no-repeat;
            }
        }
    }

    a[rel="external"]::after {
        height: auto !important;
    }
}

.landscape-carousel__caption-content {
    p {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.5;
        color: #fff;    
        width: 100%;
        margin: 0;

        span {
            padding: 0 0.5rem;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                background-color: #00aeef;
                left: 0;
                right: 0;
                top: -0.25rem;
                bottom: -0.25rem;
            }
        }
    }
}

.landscape-carousel__dots {
    @include sq-flex-box;
    @include sq-flex-direction(row);
    @extend %list-reset;
    > li {
        margin-right: pxToRem(10);

        button {
            display: block;
            height: pxToRem(12);
            width: pxToRem(12);
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .15);
            border: pxToRem(1) solid #fff;
            margin: 4px 0;
            padding: 0;
            color: #757575;
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
        }

        &.slick-active {
            button {
                background-color: #fff;
            }
        }
    }
}

/* stylelint-disable */
.landscape-carousel__controls {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    @include sq-align-items(center);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: absolute;
    bottom: 4rem;
    left: 0;
}
/* stylelint-enable */

.landscape-carousel__toggler {
    display: block;
    background-color: transparent;
    height: pxToRem(14);
    width: pxToRem(12);
    border-width: 0 pxToRem(4) 0 pxToRem(4);
    border-style: none solid;
    border-color: transparent #fff;
    padding: 0;
    margin-left: .25rem;

    &.carousel-paused {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 12px;
        border-color: transparent transparent transparent #fff;
    }
}

.quicklinks__container a[rel='external']::after {
    content: none;
}
