/* Module: S */
.homepage-whatson {
    background: rgba(85, 87, 89, 0.12) url(mysource_files/bg.svg) bottom center no-repeat;
    background-size: cover;
    padding-bottom: 4rem;
    margin-bottom: -4rem;

    &__info {
        padding: pxToRem(30) pxToRem(20) pxToRem(20) pxToRem(20);
        text-align: center;

        p {
            font-size: 1.125em;
            font-style: italic;
            line-height: 1.61;
            color: $color__primary;
            margin: 0;
        }
    }

    &__more {
        text-align: center;
        display: block;

        a {
            display: inline-block;
            background-color: #f99d1c;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.938rem;
            font-weight: bold;
            padding: pxToRem(10) 3.5rem pxToRem(10) 1.25rem;
            color: #fff;
            position: relative;
            min-width: pxToRem(220);
            text-align: left;
            border: 2px solid #f99d1c;
            @include font-smoothing;
            @include sq-transition(background-color 0.3s, color 0.3s);

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                right: 1.25rem;
                width: 17px;
                background: url(mysource_files/arrow-right.svg) right center no-repeat;
            }

            &:hover {
                background-color: transparent;
                color: $color__primary;
            }
        }
    }

    &__main {
        padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
        @extend %clearfix;
    }

    &__item {
        padding-top: pxToRem(20);

        a {
            display: block;
            color: #fff;
            text-decoration: none;
            position: relative;
            min-height: pxToRem(220);
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                bottom: pxToRem(22);
                right: pxToRem(20);
                width: 17px;
                height: 13px;
                background: url(mysource_files/arrow-right-inverse.svg) 0 0 no-repeat;
                z-index: 1;
            }

            &:after {
                content: '';
                position: absolute;
                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
                bottom: 0;
                left: 0;
                right: 0;
                height: 40%;
            }

            &:hover {
                .homepage-whatson {
                    &__image {
                        @include sq-transform(scale(1.2));
                    }
                }
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        @include sq-transition(all 0.3s);
    }

    &__content {
        padding: 1rem 1.375rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;

        p {
            font-size: 0.938em;
            line-height: 1.6;
            color: #4d4d4d;
            margin: 0;
        }

    }

    &__link {        
        a {
            display: inline-block;
            text-decoration: none;
            font-size: 0.938rem;
            font-weight: bold;
            padding: 0 3.5rem 0 0;
            color: #00aeef;
            position: relative;
            margin-top: 1rem;
            min-width: pxToRem(155);
            @include font-smoothing;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                right: 1.25rem;
                width: 17px;
                background: url(mysource_files/arrow-right.svg) right center no-repeat;
            }
        }
    }

    &__date {
        color: #fff;
        background-color: #00aeef;
        position: absolute;
        top: pxToRem(8);
        right: pxToRem(8);
        width: pxToRem(60);
        height: pxToRem(60);
        font-size: 1em;
        font-weight: bold;
        line-height: 1.25;
        text-align: center;
        padding: 0.6rem 0.5rem;
        text-transform: uppercase;        
        @include font-smoothing;
    }

    h2 {
        color: $color__primary;
        font-size: 2.25em;
        line-height: 1.25;
        margin: 0;
        padding: 0.5rem 0;
    }

    h3 {
        color: #fff;
        font-size: 1.250em;
        margin: 0;
        padding: 0;
        @include font-smoothing;
    }

}