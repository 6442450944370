/* Module: S */
.top-nav,
.main-header__wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
}

.top-nav__item {
    padding: 0 1.75rem;
}

.top-nav__wrapper {
    padding: 1rem;
}