/* Module: S */
.quicksections {
    &__container {
        width: 100%;
        overflow: hidden;
    }

    ul {
        margin: -1px;
        padding: 0;
        @extend %clearfix;

        li {
            list-style-type: none;
            width: 50%;
            display: block;
            padding: 0;
            margin: 0;
            float: left;
            border: solid 1px #efefef;

            a {
                font-size: 0.875rem;
                font-weight: bold;
                line-height: 1.71;
                color: #00447c;
                text-align: center;
                text-decoration: none;
                padding: pxToRem(30) 1rem pxToRem(20) 1rem;
                display: block;
                position: relative;

                &:hover {
                    text-decoration: underline;
                }

                .img {
                    position: absolute;
                    top: pxToRem(30);
                    left: 50%;
                    @include sq-transform(translateX(-50%));
                }

                span {
                    background-position: top center;
                    background-repeat: no-repeat;
                    display: block;
                    padding-top: pxToRem(44);
                }
            }
        }
    }
}