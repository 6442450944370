/* Module: S */
.main__footer {
    @include sq-flex-box;
    @include sq-flex-direction;
    @include sq-flex-direction(column);
    font-size: 95%;
    padding-top: 4.5rem;
    margin-top: 3.5rem;
}

/* stylelint-disable */
.footer-links__wrapper {
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-flex-grow(1);
    @include sq-justify-content(space-between);
    margin-left: auto;
    margin-right: auto;
}
/* stylelint-enable */

.footer-links__column {
    width: auto;
    @include sq-flex-basis(auto);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.footer-link,
.footer-phone__link,
.footer-contact__heading {
    font-size: pxToRem(18);
}

.footer-social__wrapper {
}

.footer-links__row {
    ul {
        li {
            &:first-child {
                width: auto;

                &:after {
                    display: inline-block;
                }
            }
        }
    }
}
