/* Module: S */
.landscape-carousel__caption-wrapper {
	max-width: 50%;
}

.landscape-carousel__item {
	padding: 5rem 2rem 11.5rem 2rem
}

.landscape-carousel__caption-content {
	p {
		font-size: 2.625rem;
	}
}

.landscape-carousel__caption-wrapper {
	margin: 0 auto;
    max-width: 80rem;
}

.landscape-carousel__caption-content {
    max-width: 50%;
}

.landscape-carousel__controls {
	left: 50%;
    @include sq-transform(translateX(-50%));
	padding: 0 2rem;
	bottom: 5rem;
}

.landscape-carousel__caption-cta {
	a {
		margin-top: 2.5rem;
	}
}