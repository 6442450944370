// TOP NAV
$top-nav__bg: $color__secondary;
$top-nav__color: #fff;
$main-menu__bg: #fff;
$main-nav__color: #2e2e2e;
$main-nav__color--hover: $color__primary;
$main-nav-top-bar__color: $main-nav__color--hover;
$header-search-input__border: $color__primary;
$header-search-input__color: #4d4d4d;
$header-search-button__color: $color__secondary;
$header-search-mobile__bg: #efefef;
$header-search-focus__bg: #f4f4f4;

$menu-arrow__color: #000;
$mega-menu__bg: #fff;
$mega-menu-border__color: $color__primary;
$mega-menu-link__color: #00447c;
$mega-menu-sub-nav-title__color: $color__primary;
$mega-menu-sub-nav-link__color: #58595b;

$mobile-menu-lvl1__bg: #efefef;
$mobile-menu-lvl2__bg: $color__primary;
$mobile-menu-lvl3__bg: darken($color__primary, 6%);

$azure: #00aeef;
