/* Module: S */
// Custom Pixel values
$container_width_large: 80%;
$container_maxwidth_large: 1080px;
$container_width_med: 1040px;
$container_width_small: 800px;

$custom_white: #ffffff;
$custom_blue: #012644;
$custom_grey: #d6d6d6;
$custom_darkgrey: #869791;
$custom_black: #000000;


body {
    .wrapper {
        .main__content {
            .video-gallery-container {
                .video-settings-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 20px 0px;
                    .item-setthings  {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 10px;
                        display: none;
                        &.view-container {
                            @media only screen and (max-width: $container_width_small) {
                                display: none;
                            }
                        }
                        h1, h2, h3, h4, h5, h6 {
                            &.title {
                                margin: 0;
                            }
                        }
                    }
                    select {
                        cursor: pointer;
                    }
                }
                .video-item-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    column-gap: 10px;
                    position: relative;
                    .video-linebreak {
                        width: 100%;
                        h1, h2, h3, h4, h5, h6 {
                            &.title {
                                margin: 0;
                                font-size: 24px;
                                margin-top: 0.75rem;
                                margin-bottom: 0.75rem
                            }
                        }
                    }
                    .video-item {
                        width: 300px;
                        height: auto;
                        margin-bottom: 10px;
                        position: relative;
                        overflow: hidden;
                        .bx-item {
                            width: 300px;
                            height: 200px;
                        }
                        h1, h2, h3, h4, h5, h6 {
                            &.heading {
                                margin: 0;
                                font-size: 18px;
                                margin-top: 0.75rem;
                                margin-bottom: 0.75rem;
                            }
                        }
                        .heading {
                            text-align: center;
                        }
                        .txt-date {
                            display: none;
                        }
                        [btn-video-popup] {
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            cursor: pointer;
                            &[disabled] {
                                pointer-events: none;
                                display: none;
                            }
                        }
                    }
                }
                &[select-view="3"] {
                    .video-item-container {
                    }
                    .video-item {
                        width: 300px;
                        .bx-item {
                            width: 300px;
                            height: 200px;
                        }
                    }
                }
                &[select-view="4"] {
                    .video-item-container {
                        .video-item {
                            width: 24%;
                            .bx-item {
                                width: 100%;
                                height: 50%;
                            }
                        }
                    }
                }
                &[select-view="5"] {
                    .video-item-container {
                        .video-item {
                            width: 18%;
                            .bx-item {
                                width: 100%;
                                height: 50%;
                            }
                        }
                    }
                }
                &[select-view="6"] {
                    .video-item-container {
                        .video-item {
                            width: 14%;
                            .bx-item {
                                width: 100%;
                                height: 30%;
                            }
                        }
                    }
                }
                @media only screen and (max-width: $container_width_small) {
                    .video-item-container {
                        &[video-embeds-items], &[video-embeds-items].container-group {
                            .video-item {
                                width: 100%;
                                .bx-item {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .slide-listing-cards {
                position: relative;
                .inner-container {
                    position: relative;
                    .slide-carousel__wrapper {
                        position: relative;
                        margin: 0 auto;
                        padding: 0 0 10px;
                        a {
                            &[rel="external"] {
                                &::after {
                                    background-image: none;
                                }
                            }
                        }
                        .btn-viewfull {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                        .slide-carousel {
                            position: relative;
                            .figure__image {
                                position: relative;
                                width: 100%;
                                height: auto;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                                figcaption {
                                    position: absolute;
                                    bottom: 0%;
                                    left: 0%;
                                    width: 100%;
                                    height: auto;
                                    background: $custom_blue;
                                    color: $custom_white;
                                    font-weight: 300;
                                    font-size: 1.5rem;
                                    padding: 5px 10px 8px;
                                    margin: 0;
                                    @media only screen and (max-width: $container_width_small) {
                                        font-size: 0.9rem;
                                        padding: 12px 0;
                                    }
                                }
                            }
                        }
                        .slick-dots {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            margin: auto;
                            width: fit-content;
                            padding: 0 0 15px;
                            li {
                                &[role="presentation"] {
                                    cursor: pointer;
                                    margin: auto;
                                    button {
                                        display: none;
                                    }
                                    &::before {
                                        cursor: pointer;
                                        background-color: $custom_grey;
                                        width: 10px;
                                        height: 10px;
                                    }
                                    &.slick-active {
                                        &::before {
                                            background-color: $custom_darkgrey;
                                        }
                                    }
                                }
                            }
                        }
                        .arrow {
                            cursor: pointer;
                            position: absolute;
                            bottom: 2%;
                            svg {
                                path {
                                    fill: $custom_darkgrey;
                                }
                            }
                            &.arrow-prev {
                                left: 25%;
                                svg {
                                    transform: rotate(90deg);
                                }
                                
                            }
                            &.arrow-next {
                                right: 25%;
                                svg {
                                    transform: rotate(270deg);
                                }
                                
                            }
                        }
                    }
                    .slide-border {
                        width: 50%;
                        height: 1px;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-bottom: $custom_grey solid 1px;
                    }

                    .btn-control-slide-contrainer {
                        position: absolute;
                        right: 0;
                        bottom: 70px;
                        width: fit-content;
                        height: auto;
                        z-index: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        [btn-control-slide-carousel] {
                            padding: 0;
                            margin: 0;
                            .icon-container {
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                flex-wrap: nowrap;
                                align-items: center;
                                width: fit-content;
                                .icon {
                                    width: 30px;
                                    height: 30px;
                                    margin: 0 10px 5px 0;
                                    border: 1px solid $custom_white;
                                    border-radius: 50%;
                                    svg {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        overflow: visible;
                                        path {
                                            fill: $custom_white;
                                        }
                                        line {
                                            stroke: $custom_white;
                                            stroke-width: 1;
                                        }
                                    }
                                    &.icon-pause {
                                        border: 3px solid $custom_white;
                                    }
                                }
                            }
                            &.active {
                                .icon-container {
                                    .icon {
                                        border: 3px solid $custom_white;
                                        &.icon-play {
                                            display: flex;
                                        }
                                        &.icon-pause {
                                            border: 1px solid $custom_white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    [data-video-popup] {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        z-index: 1000;
        display: none;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        * {
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
        }
        [bg-video-popup] {
            cursor: pointer;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        [data-video-popup-wrapper] {
            opacity: 0;
            margin-top: -10%;
            width: 80%;
            height: 60%;
            z-index: 1;
            @media only screen and (max-width: $container_width_small) {
                height: 25%;
                margin-top: -20%;
            }
            .video-popup-container {
                overflow: visible;
                width: 100%;
                height: 100%;
                position: relative;
            }
            .iframe-popup-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .iframe-popup {
                    opacity: 0;
                    width: 56.25%;
                    height: 100%;
                    margin: auto;
                    z-index: 1000;
                    @media only screen and (max-width: 2000px) {
                        width: 80%;
                    }
                    @media only screen and (max-width: 1000px) {
                        width: 100%;
                        height: 80%;
                    }
                    @media only screen and (max-width: 800px) {
                        width: 80%;
                        height: 100%;
                    }
                }
            }
            .title, .txt-content, * {
                color: $custom_white;
            }
            .title {
                margin-bottom: 10px;
            }
            .txt-content-wrapper {
                padding: 10px 10px 20px;
                overflow: hidden;
                background: $custom_black;
                opacity: 0.8;
                .txt-content {
                    max-height: 100px;
                    overflow-y: scroll;
                    p {
                        margin: 0;
                    }
                }
            }
        }
        [data-btn-close] {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -40px;
            right: -20px;
            cursor: pointer;
            &:before,&:after{
                content:'';
                position:absolute;
                width:36px;
                height:4px;
                background-color:$custom_white;
                border-radius:2px;
                top:16px;
                box-shadow:0 0 2px 0 #ccc;
            }
            &:before{
                -webkit-transform:rotate(45deg);
                -moz-transform:rotate(45deg);
                transform:rotate(45deg);
                left:2px;
            }
            &:after{
                -webkit-transform:rotate(-45deg);
                -moz-transform:rotate(-45deg);
                transform:rotate(-45deg);
                right:2px;
            }
        }
        &.transitionIn, &.transitionOut {
            display: flex;
            opacity: 0;
            justify-content: center;
            align-items: center;
        }
        &.active {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            [bg-video-popup] {
                background: $custom_black;
                opacity: 0.5;
                z-index: -1;
            }
            [data-video-popup-wrapper] {
                opacity: 1;
                .iframe-popup {
                    opacity: 1;
                }
            }
        }
    }

}