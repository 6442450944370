/* Module: Breadcrumbs */
.breadcrumbs {
    display: none;
}

.breadcrumbs__list {
    @extend %list-reset;
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
}

.breadcrumbs__item {
    position: relative;
    margin-right: .5rem;
    padding-right: 1.65rem;

    &:not(.breadcrumbs__current)::after {
        content: '';
        position: absolute;
        top: .35rem;
        right: .45rem;
        display: block;
        height: .65rem;
        width: .65rem;
        border-right: 3px solid $breadcrumbs-divider__color;
        border-bottom: 3px solid $breadcrumbs-divider__color;
        @include sq-transform(rotate(-45deg));
    }
}

.breadcrumbs__link {
    color: $breadcrumbs-links__color;
    text-decoration: none;
    display: block;

    &:hover,
    &:focus {
        color: $breadcrumbs-links__color--hover;
        text-decoration: underline;
    }
}

.breadcrumbs__divider {
    @include sq-inline-block;
    margin: 0 5px;
}

.breadcrumbs__current {
    font-weight: 600;
    padding-right: 0;
    margin-right: 0;
}
