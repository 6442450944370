.wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
}
//
.main__content {
    &.is-listing {
        @include sq-order(inherit);
        margin-top: 0;
    }
}
/*
--------------------
Modules
--------------------
*/
