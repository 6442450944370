/* Module: S */
.homepage-news {
	&__main {
        padding: pxToRem(20) pxToRem(10) pxToRem(20) pxToRem(10);
	}

	&__item {
		float: left;
		width: 50%;
		padding: 0 pxToRem(10);
	}
}